import React from 'react';
import { makeStyles } from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const useStyles = makeStyles((theme) => ({
  timerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2e6f9", // Light background color
    borderRadius: "50%",
    width: "150px", // Adjust as needed
    height: "150px", // Adjust as needed
    boxShadow: "0 4px 15px rgba(111, 41, 129, 0.5)", // Shadow with theme color
    marginBottom: "20px",
  },
  timer: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "#6f2981", // Theme color for text
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
}));

const CountdownComponent = ({ isPlaying = true, duration = 3, onComplete }) => {
  const classes = useStyles();

  return (
    <div className={classes.timerContainer}>
      <CountdownCircleTimer
        size={140}
        isPlaying={isPlaying}
        duration={duration}
        colors={["#6f2981", "#9b59b6", "#d2b4de", "#f2e6f9"]}
        colorsTime={[3, 2, 1, 0]}
        onComplete={onComplete}
      >
        {({ remainingTime }) => (
          <div className={classes.timer}>
            {remainingTime !== undefined ? remainingTime : ""}
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountdownComponent;