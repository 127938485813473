import React, { useState, useEffect, useRef } from "react";
import { Typography, Popover, Button, LinearProgress, CircularProgress } from "@material-ui/core";
import axios from "../../../utils/axiosConfig";
import RecordRTC from "recordrtc";
import onWindowReload from "../../../utils/reloadFunction";
import { serverConstants, localhostConstants } from "../../../utils/serverConstants";
import { PlayArrow, Stop } from "@material-ui/icons";
import CountdownComponent from "../../VideoTest/CountdownTimer";
import { RecordingConfirmationModal, WarningModal } from "../../VideoTest/WarningModal";
import { useStyles } from "../../../css/VideoInterviewStyles";
import {
  canvasToBlob,
  getPrimaryImageFromStorage,
  getFaceData,
  formatRecordingTime,
  parseTimeString,
  checkAudioQuality,
} from "./helpers";

export default (props) => {
  const classes = useStyles();
  const {
    testData,
    questionNo,
    setClearFlag,
    clearFlag,
    examId,
    setCheckFlag,
    examDetail,
    answerStatus,
    setAnswerStatus,
    marksScored,
    setMarksScored,
    totalMarks,
    setTotalMarks,
    wrongAnswerCount,
    setWrongAnswerCount,
    disable,
    setDisable,
    setTestData,
    windowSwitchCount,
    setWindowSwitch,
    isRecording,
    setIsRecording,
    isVideoSent,
    setIsVideoSent,
    isVideoUploading,
    setIsVideoUploading,
    isTimeUp,
    setIsTimeUp,
    facialDataArray,
    setFacialDataArray,
    isRecordingDone,
    setIsRecordingDone,
  } = props;

  const [noVideoUploaded, setNoVideoUploaded] = useState(false);
  const [selectAnswer, setAnswer] = React.useState("");
  const [recordVideo, setRecordVideo] = React.useState(null);
  const [blobUrl, setBlobUrl] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVideoRecorded, setIsVideoRecorded] = useState(false);
  const [uploadedVideoLink, setUploadedVideoLink] = useState("");
  const [uploadingStatus, setUploadingStatus] = React.useState({});
  const [checkArray, setCheckArray] = React.useState(testData[questionNo - 1].selectedChoiceId);
  const [startRecordingCountdown, setStartRecordingCountdown] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [timerInterval, setTimerInterval] = useState(null);
  const [recordedTime, setRecordedTime] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [stopRecordingClicked, setStopRecordingClicked] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [modalType, setModalType] = useState("");
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
  const [faceProctorInterval, setFaceProctorInterval] = useState(null);
  const [faceProctorIntervalId, setFaceProctorIntervalId] = useState(null);
  const [recordingBlob, setRecordingBlob] = useState(null);
  const [skipUpload, setSkipUpload] = useState(false);
  const [myOptions, setMyOptions] = React.useState(
    testData[questionNo - 1].selectedChoiceId.match(/\d+/g) ? testData[questionNo - 1].choiceid.replace("|^^|", ",") : ""
  );
  const [faceCheckStatus, setFaceCheckStatus] = useState({
    inProgress: false,
    lastResult: null,
    lastCheckTime: null,
  });
  const [isCheckingAudio, setIsCheckingAudio] = useState(false);
  const userVideoRef = useRef(null);

  useEffect(() => {
    if (!isRecording) {
      setFacialDataArray([]); // Reset facial data when recording stops
    }
  }, [isRecording]);

  React.useEffect(() => {
    if (isRecording) {
      setShowModal(false);
      setModalType("");
      setSkipUpload(false);
      setNoVideoUploaded(false);
    }
  }, [isRecording]);

  useEffect(() => {
    if (!faceCheckStatus.inProgress && faceCheckStatus.lastResult === false && isRecording) {
      const retryTimeout = setTimeout(() => {
        setFaceCheckStatus((prev) => ({
          ...prev,
          inProgress: false,
        }));
      }, 3000);
      return () => clearTimeout(retryTimeout);
    }
  }, [faceCheckStatus, isRecording]);

  React.useEffect(() => {
    if (noVideoUploaded) {
      stopRecording();
      setIsRecordingDone(true);
      setShowModal(true);
    }
  }, [noVideoUploaded]);

  const startRecordingWithConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalType("");
    setNoVideoUploaded(false);
    setIsStartButtonDisabled(false);
    setSkipUpload(false);
  };

  const handleRetryRecording = () => {
    setShowModal(false);
    setModalType("");
    setNoVideoUploaded(false);
    setIsStartButtonDisabled(false);
    setSkipUpload(false);
    setBlobUrl("");
    setRecordingBlob(null);
    setIsVideoSent(false);
    setRecordingTime(0);
    setUploadingStatus((prev) => ({
      ...prev,
      [questionNo]: { uploading: false, success: false },
    }));
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  onWindowReload(examId, examDetail, setWindowSwitch, setTestData, windowSwitchCount, testData);

  const open = Boolean(anchorEl);

  const getAnswer = (values) => {
    if (testData[questionNo - 1].selectedChoiceId.match(/\d+/g)) {
      if (testData[questionNo - 1].selectedChoiceId.indexOf("null") == -1) {
        testData[questionNo - 1].buttonStyle = "Answered";
      }
    }
  };

  const startRecording = async () => {
    try {
      setFacialDataArray([]);
      setSkipUpload(false);
      setNoVideoUploaded(false);
      setShowModal(false);
      setModalType("");
      setRecordingTime(0);

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 640 }, height: { ideal: 480 }, facingMode: "user" },
        audio: true,
      });
      const options = {
        type: "video",
        mimeType: "video/mp4",
        videoBitsPerSecond: 512000,
        frameInterval: 90,
      };
      const mediaRecorder = new RecordRTC(stream, options);
      mediaRecorder.startRecording();

      setRecordVideo((prev) => mediaRecorder);
      setIsRecording(true);
      setIsVideoSent(true);
      setUploadingStatus((prev) => ({ ...prev, [questionNo]: { uploading: false, success: false } }));

      if (userVideoRef.current) {
        userVideoRef.current.srcObject = stream;
        userVideoRef.current.play();
      }

      const videoElement = document.createElement("video");
      videoElement.srcObject = stream;
      videoElement.muted = true;
      videoElement.play();

      await new Promise((resolve) => {
        videoElement.onloadedmetadata = () => resolve();
      });

      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      canvas.getContext("2d").drawImage(videoElement, 0, 0);

      const imageBlob = await canvasToBlob(canvas);
      const imageName = `${props.examDetail[0].userid}_${props.examId}_${props.testData[props.questionNo - 1].qid}.jpg`;

      const formData = new FormData();
      formData.append("file", imageBlob, imageName);

      try {
        const response = await axios.post("/result/uploadImg", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        setUserImg(response.data.imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }

      const startTime = Date.now();
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = Math.floor((currentTime - startTime) / 1000);
        if (elapsedTime >= 91) {
          clearInterval(intervalId);
        } else {
          const recordedTime = formatRecordingTime(elapsedTime);
          setRecordingTime(recordedTime);
        }
      }, 1000);
      setTimerInterval(intervalId);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  console.log("Facial Data Array: ", facialDataArray);

  const uploadVideo = async (blob, filename, currentRecordingTime) => {
    if (skipUpload) {
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: false, success: false },
      }));
      setIsVideoSent(false);
      return;
    }

    // Check if facial data array is empty
    if (facialDataArray.length === 0) {
      setSkipUpload(true);
      setNoVideoUploaded(true);
      setModalType("noFacialData");
      setShowModal(true);
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: false, success: false },
      }));
      setIsVideoSent(false);
      return;
    }

    const formData = new FormData();
    formData.append("video", blob, filename);
    setIsVideoUploading(true);

    try {
      const response = await axios({
        method: "POST",
        url: "/result/uploadvideo",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const { videoUrl, file_name } = response.data;
      testData[questionNo - 1].answerResourcePath = videoUrl;
      testData[questionNo - 1].answerResourceName = file_name;
      testData[questionNo - 1].descriptiveText = "Not Required";
      testData[questionNo - 1].selectedChoiceId = "1";
      testData[questionNo - 1].recordedTime = currentRecordingTime;
      testData[questionNo - 1].userImage = userImg;
      testData[questionNo - 1].facialData = facialDataArray;
      setFacialDataArray([]);
      setUploadedVideoLink(videoUrl);
      setIsVideoUploading(false);
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: false, success: true },
      }));
      setIsVideoRecorded(true);
      setIsVideoSent(false);
      setCheckFlag(true);
      setRecordingTime(0);
      setIsRecordingDone(true);
    } catch (error) {
      console.error("Error uploading video file on azure:", error);
      setIsVideoUploading(false);
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: false, success: false },
      }));
      setIsVideoSent(false);
      setNoVideoUploaded(true);
      setModalType("corruptVideo");
      setShowModal(true);
    }
  };

  const stopRecording = async () => {
    setFaceCheckStatus({ inProgress: false, lastResult: null, lastCheckTime: null });
    clearInterval(faceProctorIntervalId);
    clearInterval(timerInterval);

    if (recordVideo) {
      setUploadingStatus((prev) => ({
        ...prev,
        [questionNo]: { uploading: true, success: false },
      }));

      recordVideo.stopRecording(async () => {
        const blob = recordVideo.getBlob();
        if (blob.size === 0) {
          setNoVideoUploaded(true);
          setModalType("corruptVideo");
          setShowModal(true);
          setUploadingStatus((prev) => ({
            ...prev,
            [questionNo]: { uploading: false, success: false },
          }));
          setIsVideoSent(false);
          return;
        }

        const url = URL.createObjectURL(blob);
        const currentRecordingTime = recordingTime;
        setRecordedTime(currentRecordingTime);
        setBlobUrl(url);
        setIsRecording(false);
        setRecordingBlob(blob);

        const filename = `${props.examDetail[0].userid}_${props.examId}_${testData[questionNo - 1].qid}.mp4`;

        if (skipUpload) {
          setUploadingStatus((prev) => ({
            ...prev,
            [questionNo]: { uploading: false, success: false },
          }));
          setIsVideoSent(false);
          if (!showModal) {
            setShowModal(true);
          }
          return;
        }

        try {
          if (userVideoRef.current && userVideoRef.current.srcObject) {
            const canvas = document.createElement("canvas");
            canvas.width = userVideoRef.current.videoWidth;
            canvas.height = userVideoRef.current.videoHeight;
            canvas.getContext("2d").drawImage(userVideoRef.current, 0, 0);

            const finalImageBlob = await canvasToBlob(canvas);
            const primaryImage = getPrimaryImageFromStorage();

            if (primaryImage) {
              const finalFaceResult = await getFaceData(finalImageBlob, primaryImage);
              if (!finalFaceResult.success || !finalFaceResult.face_visible || finalFaceResult.num_faces === 0) {
                setSkipUpload(true);
                setNoVideoUploaded(true);
                setModalType("faceNotDetected");
                setShowModal(true);
                setUploadingStatus((prev) => ({
                  ...prev,
                  [questionNo]: { uploading: false, success: false },
                }));
                setIsVideoSent(false);
                return;
              } else if (
                finalFaceResult.face_match_result &&
                !finalFaceResult.face_match_result.verified &&
                finalFaceResult.face_match_result.similarity_score < 15
              ) {
                setSkipUpload(true);
                setNoVideoUploaded(true);
                setModalType("faceNotMatching");
                setShowModal(true);
                setUploadingStatus((prev) => ({
                  ...prev,
                  [questionNo]: { uploading: false, success: false },
                }));
                setIsVideoSent(false);
                return;
              }
            }
          }
        } catch (error) {
          console.error("Error in final face verification:", error);
        }

        const recordingTimeInSeconds = parseTimeString(recordingTime);
        if (recordingTimeInSeconds < 20) {
          setModalType("shortDuration");
          setShowModal(true);
          setUploadingStatus((prev) => ({
            ...prev,
            [questionNo]: { uploading: false, success: false },
          }));
          setIsVideoSent(false);
          return;
        }

        setIsStartButtonDisabled(true);
        // Check audio quality before uploading
        setIsCheckingAudio(true);
        checkAudioQuality(
          blob,
          () => {
            setModalType("noAudio");
            setShowModal(true);
            // Handle no audio scenario
          }
        ).then((result) => {
          if (result === "good_audio") {
            uploadVideo(blob, filename, currentRecordingTime);
          }
        });
      });
    }
  };

  React.useEffect(() => {
    const recordingTimeInSeconds = parseTimeString(recordingTime);
    if (isTimeUp && isRecording && recordingTimeInSeconds >= 20) {
      stopRecording();
    } else if (isTimeUp && isRecording && recordingTimeInSeconds < 20) {
      setIsRecordingDone(true);
    } else if (isTimeUp && !isRecording) {
      setIsRecordingDone(true);
    }
  }, [isTimeUp, recordingTime]);

  React.useEffect(() => {
    if (recordingTime === "01.30 min") {
      stopRecording();
    }
  }, [recordingTime]);

  React.useEffect(() => {
    if (skipUpload && !showModal && (modalType === "faceNotDetected" || modalType === "faceNotMatching")) {
      setShowModal(true);
    }
  }, [skipUpload, modalType]);

  const playRecordedVideo = () => {
    if (blobUrl) {
      const video = document.getElementById("recordedVideo");
      video.src = blobUrl;
      video.play();
    }
  };

  const handleRecordingButton = () => {
    setStopRecordingClicked(true);
    if (isRecording) {
      stopRecording();
    } else if (blobUrl) {
      playRecordedVideo();
    } else {
      startRecording();
    }
  };

  React.useEffect(() => {
    setIsRecordingDone(false);
    setUserImg("");
    setIsStartButtonDisabled(false);
    setIsVideoUploading(false);
    if (questionNo === testData.length) {
      setDisable(true);
    }
    if (clearFlag) {
      setAnswer("");
      setMyOptions("");
      setCheckArray("");
      let temp = answerStatus;
      setAnswerStatus(temp);
      testData[questionNo - 1].buttonStyle = "Not Answered";
      let collectData = { quesData: testData, examDetail: examDetail };
      let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
      window.localStorage.setItem(examId, encode);
      setClearFlag(false);
    }
    let collectData = { quesData: testData, examDetail: examDetail };
    let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))));
    window.localStorage.setItem(examId, encode);

    setRecordVideo(null);
    setBlobUrl("");
    setRecordingBlob(null);
    setSkipUpload(false);

    if (!isVideoRecorded) {
      if (userVideoRef.current) {
        const stream = userVideoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
      }
    }
    setStopRecordingClicked(false);
  }, [clearFlag, blobUrl, questionNo, myOptions, selectAnswer, answerStatus, checkArray]);

  getAnswer(checkArray);

  return (
    <div>
      <>
        <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span className="question-num">
            Q .{questionNo} of {testData.length}
          </span>
        </Typography>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{ paper: classes.paper }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{testData[questionNo - 1].qid}</Typography>
        </Popover>
        {examDetail[0].selectedLanguage == null ||
        examDetail[0].selectedLanguage == "en-IN" ||
        examDetail[0].include_english == true ||
        (examDetail[0].selectedLanguage != null && examDetail[0].selectedLanguage != "en-IN" && testData[questionNo - 1].translated_qtext == null) ? (
          <span
            dangerouslySetInnerHTML={{ __html: testData[questionNo - 1].qtext }}
            style={{ fontWeight: "bold", fontSize: "1.2em", color: "#333" }}
          />
        ) : null}
        <div className={classes.container}>
          {!isRecording ? (
            <>
              <div className="videodiv">
                {startRecordingCountdown ? (
                  <CountdownComponent
                    isPlaying={startRecordingCountdown}
                    duration={3}
                    onComplete={() => {
                      startRecording();
                      setStartRecordingCountdown(false);
                    }}
                  />
                ) : (
                  <video
                    id="recordedVideo"
                    src={
                      recordingBlob && blobUrl
                        ? blobUrl
                        : `${
                            serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
                              ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).azureStorageURL
                              : localhostConstants.azureStorageURL
                          }${testData[questionNo - 1].answerResourceName}`
                    }
                    controls="true"
                    type="video/mp4"
                    className={classes.video}
                  />
                )}
              </div>
              {!startRecordingCountdown ? (
                <>
                  {uploadingStatus[questionNo] &&
                  (uploadingStatus[questionNo].uploading || stopRecordingClicked) &&
                  !uploadingStatus[questionNo].success ? (
                    <>
                      <p style={{ color: "#333", marginTop: "8px", fontSize: "16px", fontWeight: "bold" }}>
                        <span>
                          <CircularProgress style={{ height: "14px", width: "14px", marginRight: "4px", color: "#6f2981" }} color="secondary" />
                        </span>{" "}
                        Video is uploading...
                      </p>
                      <LinearProgress color="#6f2981" />
                    </>
                  ) : testData[questionNo - 1].answerResourceName || testData[questionNo - 1].disabled ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                        <Typography variant="body2" gutterBottom style={{ margin: "1px", marginRight: "16px" }}>
                          Recorded Time: <b>{testData[questionNo - 1].recordedTime}</b>
                        </Typography>
                      </div>
                      <Typography style={{ color: "#4CAF50", fontSize: "16px", fontWeight: "bold", margin: "0" }}>
                        Video saved successfully !
                      </Typography>
                    </>
                  ) : null}

                  {recordingBlob && skipUpload ? (
                    <Button
                      onClick={handleRetryRecording}
                      className={classes.button}
                      startIcon={<PlayArrow />}
                      size="large"
                      style={{ marginRight: "10px" }}
                    >
                      Retry Recording
                    </Button>
                  ) : null}

                  <Button
                    onClick={startRecordingWithConfirmation}
                    disabled={isStartButtonDisabled || testData[questionNo - 1].answerResourcePath || testData[questionNo - 1].disabled}
                    className={classes.button}
                    startIcon={<PlayArrow />}
                    size="large"
                  >
                    Start Recording
                  </Button>

                  <RecordingConfirmationModal
                    open={showConfirmation}
                    onClose={(confirmed) => {
                      setShowConfirmation(false);
                      if (!confirmed) return;
                      setStartRecordingCountdown(true);
                    }}
                    onConfirm={() => {
                      setShowConfirmation(false);
                      setStartRecordingCountdown(true);
                    }}
                  />

                  <WarningModal open={showModal} onClose={handleCloseModal} modalType={modalType} onRetry={handleRetryRecording} />
                </>
              ) : null}
            </>
          ) : (
            <div className={classes.container}>
              <div className="videodiv">
                <video id="userVideo" ref={userVideoRef} className={classes.video} playsInline muted />
                <Typography variant="body2" gutterBottom style={{ margin: "1px" }}>
                  Recording Time: <span style={{ color: "#6f2981" }}>{recordingTime}</span>
                </Typography>
              </div>
              <>
                <p>Click below button to stop recording</p>
                <div>
                  <Button size="large" onClick={handleRecordingButton} className={classes.button} startIcon={<Stop />}>
                    <b>Stop Recording</b>
                  </Button>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    </div>
  );
};