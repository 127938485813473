import React, { useState, useEffect, useRef } from "react";
import pico, {
  rgba_to_grayscale,
  update_memory,
  do_puploc,
  facefinder_classify_region,
  defaultParams,
  defaultSizeImage,
  Camvas,
} from "@msvargas97/pico.js";
import Cookies from "js-cookie";
import axios from "../../utils/axiosConfig";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as faceapi from "face-api.js";
import ImageAlertModal from "./ImageAlertModal";
import { proctoringURL } from "../../utils/constants";
import AudioAlertModal from "./AudioAlertModal";
import NoFaceModal from './NoFaceModal';
import NoMatchModal from './NoMatchModal';
import MultipleFaceModal from './MultipleFaceModal';
import saveToMongoFunction from "../../utils/saveToMongoFunction";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let mediaRecorder;




function ImagEProctoring(props) {
  const shapeRef = useRef();
  const withPupilsRef = useRef();
  const camvas = useRef("Start");
  let token = Cookies.get("token");
  const { examDetail, setProcteringError, setModelShow, ImageAlertShowModal, setImageAlertShowModal, AudioAlertShowModal, setAudioAlertShowModal, NoFaceAlertModal, setNoFaceAlertModal, facialSentiments, setFacialSentiments, NoMatchAlertModal, setNoMatchAlertModal, MultipleFaceAlertModal, setMultipleFaceAlertModal, primaryImage, setprimaryImage, setProctoringEnd, ProctoringEnd, isMongoUpdated, setisMongoUpdated, MonitoringData, ProctoringProblem, setProctoringProblem, examId, setWindowSwitch,setTestData,windowSwitchCount,testData, facialDataArray, setFacialDataArray, isRecording} = props;
  const closeInstruction = () => {
    window.location.reload(false);
  };

  // let MonitoringD = localStorage.getItem("MonitoringData");
  // MonitoringD = JSON.parse(MonitoringD);
  // console.log(MonitoringD)

  let captureIntervalId;
  let captureAudioIntervalId;
  let firstAudioCaptureIntervalId;
  let captureImageInterval = MonitoringData.result.photo_timer * 1000
  let firstCaptureAudioInterval = captureImageInterval - 15000
  let CaptureAudioInterval = captureImageInterval
  if(captureImageInterval < 30000){
    firstCaptureAudioInterval = 20000
    CaptureAudioInterval = 40000
  }
  // let firstCaptureAudioInterval = 10000


  let flag = true;
  let faceDetections1;
  let expression1;

  let conf = 0.5;

  const [snapShow, setSnapShow] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [countFace, setCountFace] = React.useState(0);
  const [hidden, setHidden] = React.useState(false);
  const [ImageCount, setImageCount] = React.useState(0);
  const [AudioCount, setAudioCount] = React.useState(0);
  const [IntervalOn, setIntervalOn] = React.useState(true);
  const [intervalCount, setIntervalCount] = useState(0);
  const [AudioIntervalCount, setAudioIntervalCount] = useState(0);
  // const [captureIntervalId, setcaptureIntervalId] = useState();
  // const [captureAudioIntervalId, setcaptureAudioIntervalId] = useState();
  // const [firstAudioCaptureIntervalId, setfirstAudioCaptureIntervalId] = useState();
  const [check, setcheck] = useState(true);
  const handleClick = () => {
    setOpen(true);
  };




  // let MonitoringData = localStorage.getItem("MonitoringData");
  // MonitoringData = JSON.parse(MonitoringData);
  // console.log(MonitoringData)



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setModal(false);
  };
  useEffect(() => {

    if(MonitoringData.result.speaker_dialect === true){
    const startaAudio = async () => {
    if(IntervalOn){
    const audioConstraints = {
      audio: {
        sampleRate: 44100, // Adjust the sample rate as needed
        noiseReduction: true, // Enable noise reduction
        gain: 1.2, // Adjust gain level (between 0 and 5)
        // echoCancellation: true,
        // noiseSuppression: true,
        // autoGainControl: true,
        // sampleRate: 44100, // Adjust the sample rate as needed
      },
    }
    navigator.mediaDevices.getUserMedia(audioConstraints)
            .then((stream) => {
              const recorder = new MediaRecorder(stream);
              mediaRecorder = recorder
            })
            .catch((error) => {
              console.error('Error accessing', error.name);
            });

          }}
    startaAudio()}
  }, [IntervalOn]);
  // useEffect(() => {
  //   if (Count === 4) {
  //     // If Count is 4, set a timeout to execute onConfirm after 3 seconds
  //     const timer = setTimeout(() => {
  //       setProctoringEnd(true)

  //     }, 3000); // 3000 milliseconds = 3 seconds

  //     // Clear the timeout if Count changes before the timeout completes
  //     return () => clearTimeout(timer);
  //   }

  // }, [Count]);

  // useEffect(() => {
  //   window.onbeforeunload = function (event) {
  //     event.preventDefault();
  //   }
  // }, []);

  const reduceImageSize = async (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // Set the canvas dimensions to the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);
        // Convert the canvas image to a lower quality or size
        const reducedImage = canvas.toDataURL('image/jpeg', 0.8); // Example quality set to 0.5
        resolve(reducedImage);
      };
      img.src = imageSrc
    });
  };

  function formatDate(date) {
    // Get individual date and time components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based, so we add 1
    const year = date.getFullYear() % 100; // Get last two digits of the year
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // Ensure each component has two digits
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedYear = year.toString().padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    // Combine components in the desired format
    const formattedDateTime = `${formattedDay}${formattedMonth}${formattedYear}${formattedHours}${formattedMinutes}`;
    return formattedDateTime;
  }
  const getaudio = async () => {

    if (mediaRecorder) {

      // Check if the MediaRecorder is not already recording
      if (mediaRecorder.state !== 'recording') {
        // Initialize an array to store audio chunks
        const audioChunks = [];

        // Start recording
        mediaRecorder.start();

        // Set a timeout to stop recording after 20 seconds
        setTimeout(async () => {
          // Stop recording
          mediaRecorder.stop();

          // Handle the recorded audio data
          mediaRecorder.ondataavailable = async(e) => {
            if (e.data.size > 0) {
              audioChunks.push(e.data);
            }

            // const audioBlob = buildWavFile(audioChunks);
            // If the total recording time reaches 10 seconds, send the audio chunk to the backend
            const audioBlob = new Blob(audioChunks, { type: "audio/webm;codecs=opus" });
            // const reducedBlob = await reduceAudioFileSize(audioBlob);
            audioChunks.length = 0; // Clear the audioChunks array
            const currentdate = new Date();
            const imagename = await formatDate(currentdate)
            let name = `${examDetail[0].id}_${imagename}.wav`

            var formData = new FormData()
            formData.append("audiofile", audioBlob, name);
            formData.append("userexamid", examDetail[0].id )
            axios({
              method: "POST",
              url: "/test/getaudio",
              data: formData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              userexamid: examDetail[0].id
            })
            .then((resp) => {
              if (MonitoringData.result.audio_action == 'S'){
                if(resp.data.speakers >= 2){
                  if(AudioCount === 3){
                    setProctoringEnd(true)
                  }
                  setAudioCount((AudioCount) => (AudioCount += 1))
                  setIntervalOn(false);
                  setAudioAlertShowModal(true)
                }
            }
            }).catch((error) => {
              // Handle any errors that occur during the upload
              console.error("Error uploading audio file:", error);
            });

          };
        }, 15000); // Capture and send audio every 10 seconds
      } else {
        console.warn('MediaRecorder is already recording');
      }
  }
  setIntervalOn(true)
}
  const saveImage = async () => {
    // const canvas = document.getElementsByTagName("canvas")[0];
    const imageSrc = document.getElementsByTagName("canvas")[0];
    const img2 = new Image();
    img2.src = imageSrc.toDataURL("image/jpeg");
    let image = imageSrc.toDataURL("image/jpeg");
    let { result, error, faceCount, expression1, expression2, facialExpressions } = await detectFaces2(img2);


    // check camera is identifying image or not
    if(faceCount === 0){
      saveToMongoFunction(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData,null,setisMongoUpdated)
      setIntervalOn(false);
      setNoFaceAlertModal(true)
            return;
      }
      // check multiple faces criteria
    if(faceCount >= 2){
      saveToMongoFunction(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData,null,setisMongoUpdated)
      setIntervalOn(false);
      setMultipleFaceAlertModal(true)
            return;
    }
      //check photo_matching condition
    if(MonitoringData.result.photo_matching === false){
      // check result value
      if(result === false){
        let image1 = await reduceImageSize(image)
        axios
          .post("/test/saveErrorImage", {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            userexamid: examDetail[0].id,
            facecount: faceCount,
            error: error,
            imagePath: image1,
            expression1: expression1,
            expression2: expression2,
          })
          .then((resp) => {
            resp.data.msg === "SUCCESS" ? setHidden(false) : setHidden(true);

            //  onStop();
          })
          .catch((err) => {
            setHidden(true);
          });
          return;
      }
      if(result === true){

        let image1 = await reduceImageSize(image)
        axios
          .post("/test/saveImage", {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            userexamid: examDetail[0].id,
            instanceid: examDetail[0].instanceid,
            imagePath: image1,
            expression1: expression1,
            expression2: expression2,
          })
          .then((resp) => {
            if (resp.data.msg === "SUCCESS") {
              setHidden(true);
            } else {
              setHidden(false);
            }
          });
          return;
      }
    }
    if(MonitoringData.result.photo_matching === true){
      if (result === false) {
          //photo_action should be S
        if (MonitoringData.result.photo_action === "S") {
            if (ImageCount === 3) {
              setProctoringEnd(true);
            }
            // MonitoringData.result.photo_action = 'S'
            setImageCount((ImageCount) => (ImageCount += 1));
            setIntervalOn(false);
            setImageAlertShowModal(true);
            //save to error images log
              let image1 = await reduceImageSize(image)
              axios
                .post("/test/saveErrorImage", {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                  },
                  userexamid: examDetail[0].id,
                  facecount: faceCount,
                  error: error,
                  imagePath: image1,
                  expression1: expression1,
                  expression2: expression2,
                })
                .then((resp) => {
                  resp.data.msg === "SUCCESS" ? setHidden(false) : setHidden(true);

                  //  onStop();
                })
                .catch((err) => {
                  setHidden(true);
                });
            return;
            // setModelShow(true)
          }
            setIntervalOn(false)
            setNoMatchAlertModal(true)

          // save to error images
            let image1 = await reduceImageSize(image)
            axios
              .post("/test/saveErrorImage", {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                userexamid: examDetail[0].id,
                facecount: faceCount,
                error: error,
                imagePath: image1,
                expression1: expression1,
                expression2: expression2,
              })
              .then((resp) => {
                resp.data.msg === "SUCCESS" ? setHidden(false) : setHidden(true);
                //  onStop();
              })
              .catch((err) => {
                setHidden(true);
              });

          return;
        }
        if(result === true){
          // Add Facial Data Array only if recording is on
          if(isRecording == true){
            const timestamp = new Date().toISOString();
            setFacialDataArray((prevArray) => [
              ...prevArray,
              { timestamp, faceCount, expression1, expression2, facialExpressions },
            ]);
          }
          let image1 = await reduceImageSize(image)
          axios
            .post("/test/saveImage", {
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
              userexamid: examDetail[0].id,
              instanceid: examDetail[0].instanceid,
              imagePath: image1,
              expression1: expression1,
              expression2: expression2,
            })
            .then((resp) => {
              if (resp.data.msg === "SUCCESS") {
                setHidden(true);
              } else {
                setHidden(false);
              }
            });
            return;
        }
    }
        // if(MonitoringData.result.sentiment_analysis === true){
        // await axios.post('/test/saveFacialAnalysisData', {
        //   headers: {
        //     'Access-Control-Allow-Origin': '*',
        //   }, userexamid: examDetail[0].id,
        //   instanceid: examDetail[0].instanceid,
        //   expression1: expression1,
        //   expression2: expression2
        // }).then(resp => {
        //   if (resp.data.msg === 'SUCCESS') {
        //     setHidden(true);
        //     console.log("saved to facialanalysis data")
        //   } else {
        //     setHidden(false);
        //   }})
        // }


  };
  const handleAudioConfirm = async () => {

    setAudioAlertShowModal(false);
    setIntervalOn(true);
    setcheck(true)
    if (AudioCount === 4) {
      // If Count is 4, set a timeout to execute onConfirm after 3 seconds
      window.onbeforeunload = function () {
        return null;  // return null to avoid pop up
      }
      window.location.reload("/dashboard");
      return;
      // Clear the timeout if Count changes before the timeout completes
    }


  };
  const handleConfirm = async () => {

    setImageAlertShowModal(false);
    if (ImageCount === 4) {
      // If Count is 4, set a timeout to execute onConfirm after 3 seconds
      window.onbeforeunload = function () {
        return null;  // return null to avoid pop up
      }
      window.location.reload("/dashboard");
      return;
      // Clear the timeout if Count changes before the timeout completes
    }

    // setModelShow(false)
    // Do something after confirmation
    setTimeout(async () => {
      await saveImage();
    }, 2000);
    setcheck(true)
    setIntervalOn(true);

  };

  const handleResumeTest = async() => {

    setIntervalOn(true)
    setNoFaceAlertModal(false)
    setMultipleFaceAlertModal(false)
    window.onbeforeunload = function () {
      return null;  // return null to avoid pop up
    }
    window.location.reload("/dashboard");
    return;
  }

  const handleNoMatchOk = async() => {

    setNoMatchAlertModal(false)
    setIntervalOn(true)
  }
  // const onBeforeUnload = (event) => {
  //   // setWindowSwitch(windowSwitchCount - 1)
  //   if (event.currentTarget){
  //     console.log("here")
  //       saveToMongoFunction(examId,examDetail,setWindowSwitch,setTestData,windowSwitchCount,testData)
  //   }


  // }
  // useEffect(() => {
  //   window.addEventListener('beforeunload',(e) => onBeforeUnload(e));
  //   // Specify how to clean up after this effect:
  //   return () => {
  //     window.removeEventListener('beforeunload',(e) => onBeforeUnload(e));
  //   };
  // });


  // const handleCancel = () => {

  //   window.location.reload('/dashboard')
  // };
  // const handleCancel = () => {
  //   setImageAlertShowModal(false);
  //   // setModelShow(false)
  //   // setOnReload(false)
  //   window.location.reload('/dashboard')
  //   window.onbeforeunload = function () {
  //     return null;  // return null to avoid pop up
  //   }
  //     // window.onbeforeunload = (event) =>{
  //     //   event.preventDefault();
  //     // }
  //     // onSubmit('111');
  //     //closeModal();

  // };

  // useEffect(() => {
  //   window.onbeforeunload = function (event) {
  //     event.preventDefault();
  //   }
  // });

  const detectFaces2 = async (img2) => {
    return new Promise(async (resolve) => {
        let isMatch = false;
        let error = null;
        let faceCount = 0;
        let expression1 = "Unknown"; // Default for primary image
        let expression2 = "Unknown"; // Default for current image
        let facialExpressions = {};

        // Prepare primary image (img1) from localStorage or state
        let img1 = new Image();
        if (primaryImage === null) {
            let primaryimage = localStorage.getItem("primaryimage");
            img1.src = primaryimage;
        } else {
            img1.src = primaryImage;
        }

        // Convert images to Blob for FormData
        const img1Blob = await fetch(img1.src).then((res) => res.blob());
        const img2Blob = await fetch(img2.src).then((res) => res.blob());

        // Prepare FormData for API request
        const formData = new FormData();
        formData.append("currentImage", img2Blob, "currentImage.png");
        formData.append("primaryImage", img1Blob, "primaryImage.png");

        try {
            // Make API call to backend
            const response = await axios.post(
                "https://video-api.myassesshub.com/api/video-scoring/check-face",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        username: "videointerview@assesshub.com",
                        password: "rVPT6vxIsme9RecB",
                    },
                }
            );

            const result = response.data;

            // Handle API response
            if (!result.success || result.error) {
                error = "N"; // Default to "No face" error
                faceCount = 0;
                resolve({ result: isMatch, error, faceCount, expression1, expression2, facialExpressions });
                return;
            }

            // Set face count
            faceCount = result.num_faces || 0;

            // Handle face count conditions
            if (faceCount === 0) {
                error = "N"; // No faces detected
                resolve({ result: isMatch, error, faceCount, expression1, expression2, facialExpressions });
                return;
            } else if (faceCount > 1) {
                error = "M"; // Multiple faces detected
                resolve({ result: isMatch, error, faceCount, expression1, expression2, facialExpressions });
                return;
            }

            // Handle face matching (only if num_faces === 1)
            if (result.face_match_result) {
                isMatch = result.face_match_result.verified;
                // Optional: Use similarity_score for custom threshold
                // isMatch = result.face_match_result.similarity_score > 60;
            }

            // Handle emotions (only if num_faces === 1)
            if (result.facial_sentiments && result.dominant_sentiment) {
                expression2 = result.dominant_sentiment || "Unknown";
                facialExpressions = result.facial_sentiments;
                // API doesn't provide emotions for primary image, so expression1 remains "Unknown"
            }

            resolve({ result: isMatch, error, faceCount, expression1, expression2, facialExpressions });
        } catch (err) {
            console.error("Error calling face detection API:", err);
            error = "N"; // Default to "No face" error on failure
            resolve({ result: isMatch, error, faceCount, expression1, expression2, facialExpressions });
        }
    });
};

  // const detectFaces = async (img2) => {
  //   return new Promise(async (resolve) => {
  //     let isMatch = false;
  //     let img1 = new Image();
  //     if(primaryImage === null){
  //       let primaryimage = localStorage.getItem('primaryimage')
  //       img1.src = primaryimage
  //     }else{
  //       img1.src = primaryImage
  //     }
  //     let error;
  //     let faceDetections2;
  //     let faceCount;
  //     let expression2;
  //     let threshold = 0.2;

  //     if (flag === true) {
  //       faceDetections1 = await faceapi
  //         .detectAllFaces(img1, new faceapi.TinyFaceDetectorOptions({ conf }))
  //         .withFaceLandmarks()
  //         .withFaceDescriptors()
  //         .withFaceExpressions();
  //       // faceDescriptor1 = await faceapi.computeFaceDescriptor(img1, faceDetections1[0]);
  //       flag = false;
  //     }
  //     faceDetections2 = await faceapi
  //       .detectAllFaces(img2, new faceapi.TinyFaceDetectorOptions({ conf }))
  //       .withFaceLandmarks()
  //       .withFaceDescriptors()
  //       .withFaceExpressions();

  //       if (faceDetections2 && faceDetections2.length > 0) {
  //         const currentImageSentiments = faceDetections2[0].expressions || [];
  //         // Update state
  //         setFacialSentiments(currentImageSentiments);
  //       } else {
  //         setFacialSentiments({}); // Reset if no face detected
  //       }
  //     expression2 = faceDetections2.map((result) => getDominantExpression(result.expressions, threshold));
  //     expression2 = expression2[0];
  //     expression1 = faceDetections1.map((result) => getDominantExpression(result.expressions, threshold));
  //     expression1 = expression1[0];
  //     faceCount = faceDetections2.length;

  //     if (faceDetections1.length === 0 || faceDetections2.length === 0) {
  //       // Handle the case where no faces were detected in one or both images
  //       error = "N";

  //       resolve({ result: isMatch, error: error, faceCount: faceCount, expression1: expression1, expression2: expression2 });
  //     } else if (faceDetections1.length >= 2 || faceDetections2.length >= 2) {
  //       // Handle the case where no faces were detected in one or both images
  //       error = "M";

  //       resolve({ result: isMatch, error: error, faceCount: faceCount, expression1: expression1, expression2: expression2 });
  //     } else if (faceDetections1.length === 1 || faceDetections2.length === 1) {
  //       // const faceDescriptor2 = await faceapi.computeFaceDescriptor(img2, faceDetections2[0]);
  //       const faceMatcher = new faceapi.FaceMatcher(faceDetections1);
  //       const matchResults = faceDetections2.map((faceDescriptor) => faceMatcher.findBestMatch(faceDescriptor.descriptor));
  //       const similarityThreshold = 0.45; // Adjust as needed
  //       isMatch = matchResults.every((result) => result.distance < similarityThreshold);

  //       // const distance = faceapi.euclideanDistance(faceDescriptor1, faceDescriptor2);
  //       // console.log(distance)
  //       // Compare the distance to a threshold to determine if the faces are a match.
  //       // const threshold = 0.4;
  //       // if (distance < threshold) {
  //       //   console.log("matching")
  //       //   isMatch = true
  //       // }

  //       resolve({ result: isMatch, error: error, faceCount: faceCount, expression1: expression1, expression2: expression2 });
  //     }
  //   });
  // };
  // function getDominantExpression(expressions, threshold) {
  //   const sortedExpressions = expressions.asSortedArray();
  //   if (sortedExpressions[0].probability >= threshold) {
  //     return sortedExpressions[0].expression;
  //   }
  //   return "Unknown";
  // }


// for production
  /*useEffect(() => {
    const loadModels = async () => {
        await Promise.all([
          faceapi.nets.ssdMobilenetv1.loadFromUri("https://user.assesshub.com/v2/models"),
          faceapi.nets.tinyFaceDetector.loadFromUri("https://user.assesshub.com/v2/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("https://user.assesshub.com/v2/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("https://user.assesshub.com/v2/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("https://user.assesshub.com/v2/models"),
        ])

    };

    loadModels();
  }, []);
  */
 // for staging
  /*useEffect(() => {
    const loadModels = async () => {
        await Promise.all([
          faceapi.nets.ssdMobilenetv1.loadFromUri("https://staging-user.assesshub.com/v2/models"),
          faceapi.nets.tinyFaceDetector.loadFromUri("https://staging-user.assesshub.com/v2/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("https://staging-user.assesshub.com/v2/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("https://staging-user.assesshub.com/v2/models"),
          faceapi.nets.faceExpressionNet.loadFromUri("https://staging-user.assesshub.com/v2/models"),
        ])

    };

    loadModels();
  }, []);
  */
  //for localhost
  // useEffect(() => {
  //       const loadModels = async () => {
  //       await Promise.all([
  //         faceapi.nets.tinyFaceDetector.loadFromUri(proctoringURL +"/models"),
  //         faceapi.nets.faceLandmark68Net.loadFromUri(proctoringURL +"/models"),
  //         faceapi.nets.faceRecognitionNet.loadFromUri(proctoringURL +"/models"),
  //         faceapi.nets.faceExpressionNet.loadFromUri(proctoringURL +"/models"),
  //       ])

  //   };

  //   loadModels();
  // }, []);
  useEffect(() => {
    const captureImage = async () => {
      if (IntervalOn) {
        if (examDetail[0].monitoringflag) {
          if (ImageCount <= 3) {
            saveImage();
            setIntervalCount((IntevalCount) => IntevalCount +=1)
          }
        }
      }
    }
      captureIntervalId = setTimeout(captureImage,  captureImageInterval)
      return () => {
        // Cleanup by clearing the capture interval when unmounting
        if (captureIntervalId) {
          clearTimeout(captureIntervalId);
        }
      }
  }, [intervalCount,IntervalOn]);
  useEffect(() => {
    if(MonitoringData.result.speaker_dialect === true){
    const captureAudio = () => {
      if (IntervalOn) {
        if (examDetail[0].monitoringflag) {
          if (AudioCount <= 3) {
            getaudio();
            setAudioIntervalCount((AudioIntevalCount) => AudioIntevalCount +=1)
          }
        }
      }
    }
    if(check == true){
      firstAudioCaptureIntervalId = setTimeout(captureAudio, firstCaptureAudioInterval)
      setcheck(false)
    }else{
      captureAudioIntervalId = setTimeout(captureAudio, CaptureAudioInterval);
    }
      return () => {
        // Cleanup by clearing the capture interval when unmounting
        if (captureAudioIntervalId) {
          clearTimeout(captureAudioIntervalId);
        }
        if (firstAudioCaptureIntervalId){
          clearTimeout(firstAudioCaptureIntervalId)
        }
      }
    }
  }, [AudioIntervalCount,IntervalOn]);

  useEffect(() => {

    // setCountFace(0);
    // async function init() {
    //   if (!pico.initialized) {
    //     await pico.load();
    //   }
    // }
    // init();
    let i;
    const ctx = document.getElementsByTagName("canvas")[0].getContext("2d");
    const image = defaultSizeImage;
    const processfn = function (video, dt) {
      // render the video frame to the canvas element and extract RGBA pixel data
      ctx.drawImage(video, 0, 0, 550, 450);

      const rgba = ctx.getImageData(0, 0, 480, 360).data;
      // prepare input to `run_cascade`
      image.pixels = rgba_to_grayscale(rgba, 480, 640);
      image.nrows = 360;
      image.ncols = 480;
      image.ldim = 480;
    };
    camvas.current = new Camvas(processfn); //camera canvas
    camvas.current.play();

    const listener = (e) => {
      // console.log(
      //   `${new Date().toLocaleString()} Camera canvas ${e.type} event...`
      // );
    };

  }, []);

  const onPlay = () => {
    camvas.current.play();
    //   setSnapShow(true);
    if (examDetail[0].monitoringflag) {
      setTimeout(saveImage(), 30000);
    }
  };
  const onPause = () => {
    camvas.current.pause();
  };
  const onStop = () => {
    camvas.current.stop();
    setSnapShow(false);
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <center>
          <label>
            <input type="checkbox" ref={withPupilsRef} defaultChecked />
            with pupils
          </label>
          <select name="shape" ref={shapeRef}>
            <option value="circle">Circle</option>
            <option value="shape">Square</option>
          </select>
        </center>
      </div>
      <div style={{ display: "none" }}>
        <center>
        <canvas width="550" height="450"></canvas>
        </center>
      </div>
      <div>
        {/* Your component content */}

        <ImageAlertModal onConfirm={handleConfirm} open={ImageAlertShowModal} Count={ImageCount} />
        <AudioAlertModal onConfirm={handleAudioConfirm} open={AudioAlertShowModal} Count={AudioCount} />
        <NoFaceModal onConfirm={handleResumeTest} open={NoFaceAlertModal} isMongoUpdated={isMongoUpdated}/>
        <MultipleFaceModal onConfirm={handleResumeTest} open={MultipleFaceAlertModal} isMongoUpdated={isMongoUpdated}/>
        <NoMatchModal onConfirm={handleNoMatchOk} open={NoMatchAlertModal}/>
        {/* Other parts of your component */}
      </div>
    </>
  );
}

export default ImagEProctoring;