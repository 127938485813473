// helpers.js

import axios from "axios";

// Helper function to convert canvas content to a Blob
export const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty"));
        }
      }, "image/jpeg");
    });
  };

  // Helper function to retrieve the primary image from storage
  export const getPrimaryImageFromStorage = () => {
    return window.localStorage.getItem("primaryimage");
  };

  // Helper function to process each question
  export const processSingleQuestion = async (qid, qtext, qmarks, videolink, categories_text, system_prompt, language, facialDataArray, userImg) => {
    try {
      // Prepare the request data
      const requestData = {
        qid,
        qtext: qtext || '', // Ensure no undefined values
        qmarks: qmarks || 10, // Default to 10 if not provided
        videolink: videolink || '',
        categories_text: categories_text || '',
        system_prompt: system_prompt || '',
        language: language || 'en', // Default to English if not specified
        facialDataArray: facialDataArray || [],
        userImg: userImg || ''
      };

      // Encode the request data using base64
      const encodedData = btoa(JSON.stringify(requestData));

      // Send the encoded data to the backend
      const response = await fetch("https://video-api.myassesshub.com/api/video-scoring/score-video-answer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "username": "videointerview@assesshub.com",
          "password": "rVPT6vxIsme9RecB"
        },
        body: JSON.stringify({ encodedData })
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the encoded response from the backend
      const encodedResponse = await response.json();

      // Comprehensive decoding method
      const decodeAPIResponse = (encodedData) => {
        try {
          // Ensure the encoded data is a string
          if (typeof encodedData !== 'string') {
            throw new Error('Encoded data is not a string');
          }

          // First, try URL decoding
          let uriDecoded;
          try {
            uriDecoded = decodeURIComponent(encodedData);
          } catch (uriError) {
            console.warn('URI Decoding failed, using original string', uriError);
            uriDecoded = encodedData;
          }

          // Then base64 decode
          let base64Decoded;
          try {
            base64Decoded = atob(uriDecoded);
          } catch (base64Error) {
            console.warn('Base64 Decoding failed', base64Error);
            throw base64Error;
          }

          // Sanitize the decoded string (remove control characters)
          const sanitizedData = base64Decoded.replace(/[\x00-\x1F\x7F]/g, '');

          // Parse JSON
          let decodedResponse;
          try {
            decodedResponse = JSON.parse(sanitizedData);
          } catch (parseError) {
            console.error('JSON Parsing error', {
              sanitizedData,
              error: parseError
            });
            throw parseError;
          }

          return decodedResponse;
        } catch (error) {
          console.error('Decoding process failed', {
            encodedData,
            error: error.message,
            stack: error.stack
          });
          throw error;
        }
      };

      // Decode the response
      const decodedResponse = decodeAPIResponse(encodedResponse.data);

      // Validate the decoded response
      if (!decodedResponse || typeof decodedResponse !== 'object') {
        throw new Error('Invalid decoded response structure');
      }

      // Log and return the decoded result
      console.log("Decoded result data --> ", decodedResponse);
      return decodedResponse;

    } catch (error) {
      // Comprehensive error handling
      console.error("Error processing question:", {
        message: error.message,
        stack: error.stack,
        inputParams: { qid, qtext, qmarks, videolink, categories_text, system_prompt, language, facialDataArray, userImg }
      });

      // Throw a standardized error
      throw new Error(`Video processing failed: ${error.message}`);
    }
  };

  // Helper function to call the face verification API
  export const getFaceData = async (currentImageBlob, primaryImage) => {
    const formData = new FormData();
    if (typeof primaryImage === "string" && primaryImage.startsWith("data:image")) {
      const response = await fetch(primaryImage);
      const blob = await response.blob();
      formData.append("primaryImage", blob, "primary-face.jpg");
    } else {
      formData.append("primaryImage", primaryImage, "primary-face.jpg");
    }
    formData.append("currentImage", currentImageBlob);
    try {
      const response = await axios.post(
        "https://video-api.myassesshub.com/api/video-scoring/check-face",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            username: "videointerview@assesshub.com",
            password: "rVPT6vxIsme9RecB",
          },
        }
      );
      console.log("Response --> ", response);
      return response.data;
    } catch (error) {
      console.error("Error calling face verification API", error);
      return { success: false, error: error.message };
    }
  };

  // Function to format recording time from seconds to "00.00 min"
  export const formatRecordingTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}.${
      seconds < 10 ? "0" + seconds : seconds
    } min`;
  };

  // Function to parse time string to seconds
  export const parseTimeString = (time) => {
    if (typeof time === "number") {
      return time; // Already in seconds
    }
    if (typeof time === "string") {
      const [minutes, seconds] = time.replace(" min", "").split(".").map(Number);
      return minutes * 60 + seconds;
    }
    console.error("Unexpected time format:", time);
    return 0;
  };

  export const checkAudioQuality = (videoBlob, onNoAudio) => {
    return new Promise(async (resolve, reject) => {
      const audioContext = new AudioContext();
      const fileReader = new FileReader();

      fileReader.onload = async () => {
        try {
          const arrayBuffer = fileReader.result;
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          const channelData = audioBuffer.getChannelData(0);

          // Check for audio presence using RMS
          const rms = calculateRMS(channelData);
          if (rms < 0.01) {
            onNoAudio();
            resolve('no_audio');
            return;
          }
          resolve('good_audio');
        } catch (error) {
          console.error('Audio analysis error:', error);
          reject(error);
        } finally {
          audioContext.close();
        }
      };

      fileReader.readAsArrayBuffer(videoBlob);
    });
  };

  // Helper function to calculate RMS (Root Mean Square)
  function calculateRMS(data) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      sum += data[i] * data[i];
    }
    return Math.sqrt(sum / data.length);
  }


  // const calculateRMS = (channelData) => {
  //   let sum = 0;
  //   for (let i = 0; i < channelData.length; i++) {
  //     sum += channelData[i] ** 2;
  //   }
  //   return Math.sqrt(sum / channelData.length);
  // };

  const isGibberish = (text) => {
    const vowelMatch = text.match(/[aeiou]/gi);
    const consonantMatch = text.match(/[bcdfghjklmnpqrstvwxyz]/gi);
    const vowels = vowelMatch ? vowelMatch.length : 0;
    const consonants = consonantMatch ? consonantMatch.length : 0;
    return vowels === 0 || consonants / vowels > 5;
  };

  const fallbackCheck = (channelData) => {
    const rms = calculateRMS(channelData);
    return rms > 0.05 ? 'good_audio' : 'no_speech'; // Basic fallback
  };