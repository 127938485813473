// styles.js
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: "0px 5px",
  },
  popover: {
    pointerEvents: "none",
  },
  videodiv: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
  },
  button: {
    color: "#ffffff",
    backgroundColor: "#D32F2F", // Adjusted shade of red for hover state
    "&:disabled": {
      backgroundColor: "#A9A9A9", // Change the background color to indicate disabled state
      color: "#ffffff", // Change the text color to indicate disabled state
      cursor: "not-allowed", // Change cursor to indicate not clickable
    },
    "&:hover": {
      color: "#ffffff",
      background: "#FF5733",
    },
  },
  container: {
    marginTop: "3px",
    width: "100%",
    maxWidth: "100vw",
    height: "60%",
  },
  video: {
    width: "100%", // Ensure the video takes the full width of its container
    height: "100%", // Ensure the video takes the full height of its container
    objectFit: "contain", // Maintain aspect ratio without stretching
    // aspectRatio: 16 / 9,
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    // Media queries for adjusting width and height
    [theme.breakpoints.up("sm")]: {
      width: "70%", // For medium-sized screens and larger
    },
    [theme.breakpoints.up("md")]: {
      width: "60%", // For large-sized screens and larger
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%", // For extra-large-sized screens
    },
  },
  dialog: {
    // Add your custom styles here
    // For example:
    "& .MuiDialog-paper": {
      // Styling for the dialog paper
      backgroundColor: "#f0f0f0", // Example background color
      padding: theme.spacing(2), // Example padding
    },
    "& .MuiDialogTitle-root": {
      // Styling for the dialog title
      backgroundColor: "#ccc", // Example background color
    },
    "& .MuiDialogContent-root": {
      // Styling for the dialog content
      padding: theme.spacing(2), // Example padding
    },
    "& .MuiDialogActions-root": {
      // Styling for the dialog actions
      justifyContent: "space-between", // Example: Align actions to the space between
    },
    // Add more styles as needed...
  },
  timerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2e6f9", // Light background color
    borderRadius: "50%",
    width: "150px", // Adjust as needed
    height: "150px", // Adjust as needed
    boxShadow: "0 4px 15px rgba(111, 41, 129, 0.5)", // Shadow with theme color
    marginBottom: "20px",
  },
  timer: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "#6f2981", // Theme color for text
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
}));