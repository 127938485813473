import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "380px"
  }
}));

export const RecordingConfirmationModal = ({
  open,
  onClose,
  onConfirm
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      disableEnforceFocus={true}
      aria-labelledby="recording-modal-title"
      aria-describedby="recording-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onClose={() => onClose(false)}
    >
      <Fade in={open}>
        <div className={classes.paper} style={{ textAlign: "justify" }}>
          <h3
            id="recording-modal-title"
            style={{
              color: '#6f2981',
              marginBottom: "30px",
              textAlign: 'center'
            }}
          >
            Recording Limit
          </h3>
          <p
            id="recording-modal-description"
            style={{ marginBottom: "30px" }}
          >
            You have to record for{" "}
            <span style={{ color: "#6f2981" }}>
              <b>
                AT LEAST <u>20</u> seconds
              </b>
            </span>{" "}
            to successfully complete this question.
          </p>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: '10px'
          }}>
            <button
              className="btn btn-blue" style={{ borderRadius: "10px" }}
              onClick={() => {
                onClose(false);
                onConfirm(true);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export const WarningModal = ({
  open,
  onClose,
  modalType,
  onRetry,
  isProcessing = false
}) => {
  const classes = useStyles();

  // State to store the last valid modalType
  const [lastModalType, setLastModalType] = React.useState(modalType);

  // Update lastModalType only if modalType is valid
  React.useEffect(() => {
    if (modalType) {
      setLastModalType(modalType);
    }
  }, [modalType]);

  const getTitle = () => {
    switch (lastModalType) {
      case "noAudio":
        return "No Audio Detected";
      case "noSpeech":
        return "No Speech Detected";
      case "highNoise":
        return "Excessive Background Noise";
      case "faceWarning":
        return "Face Detection Warning";
      case "faceNotDetected":
        return "Face Not Detected";
      case "faceNotMatching":
        return "Identity Verification Failed";
      case "identityWarning":
        return "Identity Verification Issue";
      case "shortDuration":
        return "Duration Too Short";
      case "corruptVideo":
        return "Video Upload Error";
       case "noFacialData":
        return "Facial Analysis Incomplete";
      default:
        return "Warning";
    }
  };

  const getMessage = () => {
    switch (lastModalType) {
      case "noAudio":
        return "No audio was detected in your recording. Please ensure your microphone is working and try again.";
      case "noSpeech":
        return "No Human speech was detected in your recording. Please ensure you are speaking clearly and try again.";
      case "highNoise":
        return "There is too much background noise in your recording. Please find a quieter environment and try again.";
      case "faceWarning":
        return "No face was detected during the recording. Please ensure you are visible in the camera frame and try again.";
      case "faceNotDetected":
        return "We couldn't detect your face in the camera frame. Please ensure your face is clearly visible and try again.";
      case "faceNotMatching":
        return "Your face doesn't match with your registered profile. Please ensure proper lighting and positioning, then try again.";
      case "identityWarning":
        return "There seems to be an issue verifying your identity. Please make sure your face is clearly visible and matches your profile photo.";
      case "noFacialData":
        return "Video duration is too short to analyze the facial data. Please ensure you are visible in the camera frame and try again.";
      case "shortDuration":
        // return "Your video recording should be AT LEAST 20 seconds long to move forward.";
        return (
          <>
            Your video recording should be{" "}
            <span style={{ color: "#6f2981" }}>
              <b>
                AT LEAST <u>20</u> seconds
              </b>
            </span>{" "}
            long to move forward.
          </>
        );
      case "corruptVideo":
        return "The uploaded video appears to be corrupted. Please re-record the video.";
      default:
        return "An error occurred. Please try again.";
    }
  };

  return (
    <Modal
      open={open}
      disableEnforceFocus={true}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      onClose={onClose}
    >
      <Fade in={open}>
        <div className={classes.paper} style={{ textAlign: "justify" }}>
          <h3
            id="transition-modal-title"
            style={{
              color: '#6f2981',
              marginBottom: "30px",
              textAlign: 'center'
            }}
          >
            {getTitle()}
          </h3>
          <p
            id="transition-modal-description"
            style={{ marginBottom: "30px" }}
          >
            {getMessage()}
          </p>
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
            gap: '10px'
          }}>
            <button
              className="btn btn-blue" style={{ borderRadius: "10px" }}
              onClick={onRetry}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : 'Retry'}
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
